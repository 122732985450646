.active-session {
    background-color: #662d91;
    color: white;
}

.sessionContainer {
    width: 100%;
    height: 100%;
    padding: 1em;
    background: linear-gradient(180deg, #f9f9f9, #f2f2f2, 5%, #f2f2f2, 95%, #d9d9d9);
    display: flex;
    flex-flow: column;
}

.sessionContainer :global(.ant-tabs-tab .anticon.anticon-close-square) {
  color: red;
  margin-left: 10px;
}

.sessionContainer :global(.anticon:hover) {
  background-color: #F08080;
  color: white;
}

.sessionContainer :global(.addCloudProviderTab) {
  font-style: italic;
}

.cloudProviderModal {
  min-width: 600px;
  max-width: 100%;
}

.cloudProviderModal :global(.ant-row) {
  margin-bottom: 16px;
}

.cloudProviderModal :global(.ant-modal-body button) {
  width: 100%;
  padding: 4px;
}

.cloudProviderModal :global(.ant-modal-body button img) {
  height: 100%;
}

.serverTabs :global(.ant-row > div) {
  height: 56px;
}

.sessionFooter {
  text-align: right;
  height: 30px;
}

.sessionFooter .desiredCapsLink {
  float: left;
}

.tabText img {
  height: 20px;
  filter: grayscale(100%);
}

:global(.ant-tabs-tab-active) .tabText img {
  filter: none;
}

.serverTabs {
  min-height: 160px;
}

.serverTabs :global(.ant-tabs-bar) {
  padding-top: 2em;
  margin-top: -1em;
}

.serverTabs :global(.ant-tabs-nav-wrap) {
  -webkit-app-region: drag;
}

.scrollingTabCont {
  border-bottom: 1px solid #d9d9d9;
  margin-bottom: 15px;
  margin-top: 15px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
}

.scrollingTabCont :global(.ant-tabs-bar) {
  margin-bottom: 0px;
}

.scrollingTabCont :global(.ant-tabs-content) {
  height: 100%;
}

.scrollingTab {
  overflow-y: scroll;
  height: 100%;
  padding-top: 16px;
}

.scrollingTab::-webkit-scrollbar {
  width: 0px;
}

.saved-sessions,
.saved-sessions :global(.ant-col),
.saved-sessions :global(.ant-table),
.saved-sessions :global(.ant-table-wrapper) {
  height: 100%;
}

.saved-sessions :global(.ant-table) {
  overflow-y: auto;
  height: calc(100% - 15px);
}

.saved-sessions :global(.ant-table::-webkit-scrollbar) {
  width: 0px;
}

.newSessionForm {
  overflow-y: auto;
  overflow-x: hidden;
  height: calc(100% - 15px);
}

.capsFormattedCol {
  height: 100%;
}

.formattedCapsCont {
  height: 100%;
}

.formattedCaps {
  height: calc(100% - 15px);
  margin-left: 12px;
  display: flex;
  flex-flow: column;
  align-items: stretch;
}

.formattedCaps :global(.ant-card-body) {
  flex: 1;
  overflow-y: auto;
}

.formattedCaps :global(.ant-card-body::-webkit-scrollbar) {
}


.formattedCaps pre {
  white-space: pre-wrap;
  word-wrap: break-word;
}


.start-session-button {
    margin-left: 1em;
}

.filepath-button {
    cursor: pointer;
}

.capsFormCol {
    min-width: 476px;
    height: 100%;
}

.capsFormRow {
  font-size: 14px;
  height: 100%;
}

.capsFormRow :global(.ant-form-item) {
  margin-bottom: 0.5em;
}

.capsValueControl {
  width: 142px;
}

.fileControlWrapper :global(.ant-input-group),
.capsValueControl textarea {
  max-width: 142px;
}

.localDesc {
    font-style: italic;
    color: #666;
    margin-top: 8px;
    margin-left: 8px;
}

.selected {
  background-color: #d6e8ff;
}

.capsEditorControls {
  position: absolute;
  right: 0;
  margin: 4px 24px;
}

.capsEditorButton {
  margin: 0 4px 0 0;
}

.capsEditor {
  width: 100%;
  height: 100%;
  border-color: lightgray;
}

.advancedSettingsContainerCol {
  width: 100%;
}

.advancedSettingsContainer {
}

.add-desired-capability-button {
  float: right;
}

.edit-session {
  margin-right: 10px;
}

.btnReload {
  float: right;
  margin-left: 8px;
}

.btnDeleteCap {
  float: right;
}

.inputDataCenter {
  margin-left: 8px;
}

.addonDataCenter {
  height: 32px;
  border-right: 1px solid #d9d9d9 !important;
}

.addonDataCenterRadioContainer {
  line-height: 32px;
  display: table-cell;
  padding-left: 11px;
}
